<style type="text/css">
    .required {
        color: #d16e6c;
        font-weight: bold;
        padding-left: 3px;
    }

</style>
<template>
    <div class="content-wrapper">
       <div class="container-xxl flex-grow-1 container-p-y">
        
          <div class="row justify-content-between mb-2">
              <div class="col-auto">
                <div class="d-flex align-items-center gap-2">
                  <h4 class="fw-bold mb-1"> Current Vehicles on Order</h4>
                </div>
              </div>
              <div class="col-auto gap-2">
                <div class="d-flex align-items-center gap-2">
	                <template v-if="filter.country == ''">
	                  <button type="button" @click="downloadChart" class="btn btn-outline-primary waves-effect">
	                    <span class="ti-xs ti ti-download me-1"></span>Download Chart
	                  </button>
	                 </template>
                  <button type="button" v-if="!$store.state.AppConfig.vehicleOrderCSVDownloading && !downloading_complete" @click="downloadData(filter.country)" class="btn btn-outline-primary waves-effect">
                    <span class="ti-xs ti ti-download me-1"></span>Download CSV
                  </button>

                  <button v-if="$store.state.AppConfig.vehicleOrderCSVDownloading" class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border me-1" role="status" aria-hidden="true"></span>
                    Downloading...
                  </button>

                  <router-link v-if="downloading_complete" @click="resetDownloadStatus" class="btn btn-primary waves-effect" :to="'/files/'+csv_file_name" target="_blank"><span class="ti-xs ti ti-download me-1"></span>Download Ready</router-link>

                   <router-link  class="btn btn-outline-primary waves-effect" to="/download-history">Download History</router-link>
                </div>
              </div>
          </div>

          <div class="card mb-4">
            <h5 class="card-header border-bottom">Filters</h5>
            <VeeForm  ref="filterForm" as="div" class="needs-validation" name="filter" v-slot="{ errors }">
              <form class="card-body">
                <div class="row g-3">

                 <div class="col-md-4">
                    <label class="form-label" for="countries"><strong>Country</strong></label>
                        <v-select 
                        :options="$store.state.AppConfig.countries"
                        :value="filter.country"
                        v-model="filter.country" 
                        label="Country"
                        :clearable="false"
                        :reduce="option => option.Country" 
                        placeholder="All"
                        name="country"
                        multiple
                        />
                  </div>

                  <div class="col-md-4">
                    <label class="form-label" for="makes"><strong>Make</strong></label>
                    <select
                      id="makes"
                      name="makes"
                      class="select2 form-select form-control"
                      v-model="filter.make"
                    >
                      <option value="">All</option>
                      <option v-if="makes.length" v-for="make in makes" :value="make.Make">{{make.Make}}</option>
                    </select>
                  </div>

                  <div class="col-md-4" >
                    <label class="form-label" for="suppliers"><strong>Suppliers</strong></label>
                    <select
                      id="suppliers"
                      name="suppliers"
                      class="select2 form-select form-control"
                      v-model="filter.supplier"
                    >
                      <option value="">All</option>
                      <option v-if="$store.state.AppConfig.suppliers.length" v-for="supplier in $store.state.AppConfig.suppliers" :value="supplier.SupplierCode">{{supplier.SupplierName}}</option>
                    </select>
                  </div>

                  <div class="col-md-4" >
                    <label class="form-label" for="fuel_types"><strong>Fuel Type</strong></label>
                    <select
                      id="fuel_types"
                      name="fuel_types"
                      class="select2 form-select form-control"
                      v-model="filter.fuel_type"
                    >
                      <option value="">All</option>
                      <option v-if="fuel_types.length" v-for="fuel_type in fuel_types" :value="fuel_type['Fuel Type']">{{fuel_type['Fuel Type']}}</option>
                    </select>
                  </div>


                </div>

                <div class="pt-4">
                  <button type="button" @click="submit" class="btn btn-primary me-sm-3 me-1">Apply</button>
                  <router-link  class="btn btn-outline-primary waves-effect" to="/dashboard">Cancel</router-link>
                </div>
              </form>
            </VeeForm>
          </div>

          <!-- <div class="card">
            <h5 class="card-header">Graphical View</h5>
            <div class="card-body">
                <div class="row g-3">
                    <div class="col-md-12">
                        <canvas ref="barChart" class="chartjs" data-height="400" height="500"></canvas>
                    </div>
                </div>
                <spinner :active="loading" :is-full-page="true"></spinner> 
            </div>
          </div> -->


         <div class="card mb-4">
            <div class="card-body">
              <div class="table-responsive scrollable-table">
                <table class="table w-100">
                  <thead>
                    <tr>
                      <th><strong>Country</strong></th>
                      <th><strong>Make</strong></th>
                      <th><strong>Model</strong></th>
                      <th><strong>Description</strong></th>
                      <th><strong>EmployeeID</strong></th>
                      <th><strong>EmployeeName</strong></th>
                      <th><strong>Order_Date</strong></th>
                      <th><strong>CO2Emissions</strong></th>
                      <th><strong>Supplier Name</strong></th>
                    </tr>
                  </thead>
                  <tbody class="table-border-bottom-0">
                    <tr v-for="data in tableData" v-if="tableData.length>0">
                      <td>{{data.Country}}</td>
                      <td>{{data.Make}}</td>
                      <td>{{data.Model}}</td>
                      <td>{{data.Description}}</td>
                      <td>{{data.EmployeeID}}</td>
                      <td>{{data.EmployeeName}}</td>
                      <td>{{$filters.dateFormat(data.Order_Date)}}</td>
                      <td>{{data.CO2Emissions}}</td>
                      <td>{{data.SupplierName}}</td>
                    </tr>
                    <tr v-else>
                        <td colspan="8" class="text-center">No record found.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
               <div class="px-2 d-flex justify-content-between py-1">      
                  <div>
                    <div class="flex-items">  
                      <div class="align-items-center  d-flex" style="padding-top:2px">
                        <span>Show </span>&nbsp;
                        <span>
                          <select style="width: fit-content;" class="form-select form-select-sm" v-model="recordsPerPage">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </span>&nbsp;&nbsp;
                        <span>Per Page</span>&nbsp;<span>(Total : {{totalData}})</span>
                      </div>
                    </div>
                  </div> 
                  <div>
                    <Pagination
                      v-if="totalData > recordsPerPage"
                      v-model="currentPage"
                      :activePage="currentPage" 
                      :per-page="recordsPerPage"
                      :total-rows="totalData">   
                    </Pagination>
                  </div>
              </div>
            </div>
          </div>
          <template v-if="filter.country == ''">
	          <div class="card mb-4">
	             <h5 class="card-header">Graphical View</h5>
	              <div class="card-body">
	                  <div class="row g-3">
	                      <div class="col-md-12" id="vehicleOnOrderChart" v-if="pieChartData.length>0">
	                          <canvas ref="pieChart" class="chartjs" width="400" height="400" ></canvas>
	                      </div>
	                      <div v-else>No record found.</div>
	                  </div>
	              </div>
	          </div> 
          </template>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import {Form, Field ,useFieldError,useFormErrors,useValidateForm,useField,useForm} from 'vee-validate';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Pagination from '../../components/Pagination';
import { jsPDF } from "jspdf";
export default {
    name: 'order-bank',
    components:{
      'VeeForm': Form,
      Datepicker,
      Field,
      Pagination,
      'v-select': vSelect,
    },
    props: [],
    data(){
        return{
           myChart: "",
           divisions: [],
           makes: [],
           vehicle_types: [],
           fuel_types: [],
           filter: {
              "country":[],
              "make":"",
              "supplier":"",
              "fuel_type": "",
              "start_date":"",
              "end_date":"",
           },
           chartData:[],
           barChartVar: null,
           cyanColor: '#00bcd4',
            yAxisMin: 0,
            yAxisMax: 400,
            loading: false,
            pieChartData: [],
          downloading_complete: false,
          csv_file_name: "",
          currentPage:1,
          recordsPerPage:10,
          tableData: [],
          totalData: 0,
        }     
    },
    created() {
      var self = this;
      self.getMakes();
      self.getFuelTypes();

      const currentDate = new Date();
      const startDate = new Date(currentDate);
      startDate.setMonth(currentDate.getMonth() - 12);
      self.filter.start_date = startDate;
      self.filter.end_date = currentDate;

      if(self.filter.start_date && self.filter.end_date){
        //self.getGraphData();
        self.getPieGraphData();
        self.getTableData();
      }
    },
    mounted() {
    	//$(".select2").select2();
    	$(".selectpicker").selectpicker();
       const purpleColor = '#836AF9',
            yellowColor = '#ffe800',
            cyanColor = '#28dac6',
            orangeColor = '#FF8132',
            orangeLightColor = '#FDAC34',
            oceanBlueColor = '#299AFF',
            greyColor = '#4F5D70',
            greyLightColor = '#EDF1F4',
            blueColor = '#2B9AFF',
            blueLightColor = '#84D0FF';
    },
    setup() {
    },
    computed:{
        
    },

    methods:{
      getMakes(){
        var self = this;
        self.$axios.get('/makes')
          .then(response => {
            if (!response.data.error){                    
                self.makes = response.data.input_data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      getFuelTypes(){
        var self = this;
        self.$axios.get('/fuel_types')
          .then(response => {
            if (!response.data.error){                    
                self.fuel_types = response.data.input_data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      async submit(){
        var self = this;
        const form = this.$refs.filterForm;
        const isValid = await form.validate();

        if (isValid.valid) {
          //self.getGraphData();
          self.getPieGraphData();
          self.getTableData();
        }
      },

      /*getGraphData(){
          var self = this;
          self.loading = true;
          self.$axios.post('/order-bank-graph', self.filter)
          .then(response => {
            if (!response.data.error){ 
                self.chartData = response.data.input_data;
                self.loading = false;
               
                const dataValues = this.chartData.map(item => item.data);
                self.yAxisMin = Math.min(...dataValues) - ((20 / 100) * Math.min(...dataValues));
                self.yAxisMax = Math.max(...dataValues) + ((10 / 100) * Math.max(...dataValues));
               
                self.updateChart();
            }
          })
          .catch(error => {
            console.error(error);
          });
      },*/

      updateChart() {
          const canvasElement = this.$refs.barChart;
          if (canvasElement && canvasElement.getContext) {

            const context = canvasElement.getContext('2d');
            context.clearRect(0, 0, canvasElement.width, canvasElement.height);

            const existingChart = Chart.getChart(canvasElement);
            if (existingChart) {
                existingChart.destroy();
            }

            this.barChartVar = new Chart(context, {
              type: 'bar',
              data: {
                labels: this.chartData.map(item => item.label),
                datasets: [
                  {
                    data: this.chartData.map(item => item.data),
                    backgroundColor: this.cyanColor,
                    borderColor: 'transparent',
                    maxBarThickness: 15,
                    borderRadius: {
                      topRight: 15,
                      topLeft: 15
                    }
                  }
                ]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                  duration: 500
                },
                plugins: {
                  tooltip: {
                    rtl: false,
                    backgroundColor: "#2f3349",
                    titleColor: "#cfd3ec",
                    bodyColor: "#a5a3ae",
                    borderWidth: 1,
                    borderColor: "#a5a3ae"
                  },
                  legend: {
                    display: false
                  }
                },
                scales: {
                  x: {
                    grid: {
                      color: "#a5a3ae",
                      drawBorder: false,
                      borderColor: "#434968",
                    },
                    ticks: {
                      color: "#a5a3ae",
                    }
                  },
                  y: {
                    min: this.yAxisMin,
                    max: this.yAxisMax,
                    grid: {
                      color:"#a5a3ae",
                      drawBorder: false,
                      borderColor: "#2f3349",
                    },
                    ticks: {
                      stepSize: (this.yAxisMax - this.yAxisMin) / 4,
                      color: "#a5a3ae"
                    },
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Order',
                    },
                  }
                }
              }
            });
          }
      },

      downloadCsv() {

        var self = this;
        const queryParams = Object.keys(self.filter)
              .map(key => {
                  if (key === 'start_date' || key === 'end_date') {
                      return encodeURIComponent(key) + '=' + encodeURIComponent(new Date(self.filter[key]).toISOString());
                  } else {
                      return encodeURIComponent(key) + '=' + encodeURIComponent(self.filter[key]);
                  }
              })
              .join('&');

        const apiUrl = '/download-csv/vehicle-on-order?' + queryParams;

        self.$axios.get(apiUrl, { responseType: 'blob' })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'vehicle-on-order.csv');
            document.body.appendChild(link);
            link.click();
        })
        .catch(error => {
            console.error('Error downloading CSV', error);
        });
      },

      /*==========================*/
      getPieGraphData(){
          var self = this;
          self.loading = true;
          self.$axios.post('/order-bank-graph', self.filter)
          .then(response => {
            if (!response.data.error){ 
                self.pieChartData = response.data.input_data;
                self.loading = false;
                
                if (self.pieChartData.length > 0) {  
                  self.$nextTick(() => {      
                    self.buildPieChart();
                   });
                }
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      buildPieChart(){
        const canvasEl= this.$refs.pieChart;

         if (canvasEl && canvasEl.getContext) {

            const context = canvasEl.getContext('2d');
            context.clearRect(0, 0, canvasEl.width, canvasEl.height);

            const existingChart = Chart.getChart(canvasEl);
            if (existingChart) {
                existingChart.destroy();
            }

            this.barChartVar = new Chart(context, {
                type: 'pie',
                data: {
                  labels: this.pieChartData.map(item => item.Country),
                  datasets: [
                    {
                      label: 'Orders',
                      backgroundColor: ['#836AF9', '#800020', '#ffe800', '#FF8132', '#4F5D70', '#2B9AFF','#28dac6', '#FDAC34', '#873260', '#84D0FF', '#8b4513','#299AFF'],
                      data:  this.pieChartData.map(item => item.Orders),
                      borderWidth: 0
                    }
                  ]
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  animation: {
                    duration: 500
                  },
                  plugins: {
                    legend: {
                      rtl: false,
                      position: 'bottom',
                      labels: {
                        usePointStyle: true,
                        padding: 10,
                        boxWidth: 8,
                        boxHeight: 8,
                        color: "#a5a3ae"
                      }
                    }
                  },
                  onHover: (event, chartElement) => {
                      event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
                  },
                  onClick: (event, elements) => {
                    if (elements.length > 0) {
                        const clickedIndex = elements[0].index;
                        const clickedCountry = this.pieChartData[clickedIndex].Country;
                        const clickedOrders = this.pieChartData[clickedIndex].Orders;

                        this.downloadData(clickedCountry);
                    }
                  }
                }
            });
          }        
      },

      downloadData(country){
          
        var self = this; 
        self.$store.commit('SET_VEHICLEORDER_CSV_DOWNLOADING', true); 
        self.$axios.post('/download-csv/vehicle-on-order', self.filter)
        .then(response => {
          if (!response.data.error){ 
        	  self.$store.commit('SET_VEHICLEORDER_CSV_DOWNLOADING', false);
              self.downloading_complete = true;
              self.csv_file_name = response.data.input_data.csv_file_name;
          }
        })
        .catch(error => {
          console.error(error);
        });
        /*if(country){
            self.filter.country = country;
        }
         const queryParams = Object.keys(self.filter)
              .map(key => {
                  if (key === 'start_date' || key === 'end_date') {
                      return encodeURIComponent(key) + '=' + encodeURIComponent(new Date(self.filter[key]).toISOString());
                  } else {
                      return encodeURIComponent(key) + '=' + encodeURIComponent(self.filter[key]);
                  }
              })
              .join('&');

        const apiUrl = '/download-csv/vehicle-on-order?' + queryParams;

        self.$axios.get(apiUrl)
        .then(response => {*/
           /* const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'vehicle-on-order.csv');
            document.body.appendChild(link);
            link.click();*/

           /* self.downloading_complete = true;
            self.csv_file_name = response.data.input_data.csv_file_name;

            self.$store.commit('SET_VEHICLEORDER_CSV_DOWNLOADING', false);
        })
        .catch(error => {
            console.error('Error downloading CSV', error);
        });*/
      },

      resetDownloadStatus() {
          this.downloading_complete = false;
      },
      
      downloadChart() {
      	// get size of vehicleOnOrderChart page
      	let vehicleOnOrderChartHeight = jQuery('#vehicleOnOrderChart').innerHeight();
      	let vehicleOnOrderChartWidth = jQuery('#vehicleOnOrderChart').innerWidth();
      	// create a new canvas object that we will populate with all other canvas objects
      	let pdfCanvas = $('<canvas />').attr({
      		id: "canvaspdf",
      		width: vehicleOnOrderChartWidth,
      		height: vehicleOnOrderChartHeight
      	});
      	// keep track canvas position
      	let pdfctx = jQuery(pdfCanvas)[0].getContext('2d');
      	let pdfctxX = 0;
      	let pdfctxY = 0;
      	let buffer = 100;
      	// for each chart.js chart
      	jQuery("canvas").each(function(index) {
      		// get the chart height/width
      		let canvasHeight = jQuery(this).innerHeight();
      		let canvasWidth = jQuery(this).innerWidth();
      		// draw the chart into the new canvas
      		pdfctx.drawImage(jQuery(this)[0], pdfctxX, pdfctxY, canvasWidth, canvasHeight);
      		pdfctxX += canvasWidth + buffer;
      		// our report page is in a grid pattern so replicate that in the new canvas
      		if (index % 2 === 1) {
      			pdfctxX = 0;
      			pdfctxY += canvasHeight + buffer;
      		}
      	});
      	const today = new Date();
      	const yyyy = today.getFullYear();
      	let mm = today.getMonth() + 1; // Months start at 0!
      	let dd = today.getDate();
      	let h = today.getHours();
      	let i = today.getMinutes();
      	let s = today.getSeconds();
      	if (dd < 10) dd = '0' + dd;
      	if (mm < 10) mm = '0' + mm;
      	if (h < 10) h = '0'+h;
      	if (i < 10) i = '0'+i;
      	if (s < 10) s = '0'+s;
      	// create new pdf and add our new canvas as an image
      	let pdf = new jsPDF('l', 'pt', [vehicleOnOrderChartWidth, vehicleOnOrderChartHeight]);
      	pdf.text('Downloaded Date and Time: '+dd+'-'+mm+'-'+yyyy+' '+h+':'+i+':'+s, 10, 25);
      	pdf.text('Current Vehicles on Order', 555, 25);
      	pdf.addImage(jQuery(pdfCanvas)[0], 'PNG', 10, 65);
      	// download the pdf
      	pdf.save('vehicleOnOrder.pdf');
        },

      getTableData(){
          var self = this;
          self.loading = true;

          self.filter.per_page = self.recordsPerPage;
          self.$axios.post('/vehicle-on-order-data/'+ self.currentPage, self.filter)
          .then(response => {
            if (!response.data.error){ 
                self.tableData = response.data.input_data.data;
                self.totalData = response.data.input_data.total;
                self.loading = false;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      loadFirstPage(){
        var self = this;
        clearTimeout(self.debounceTimeout); // Clear the previous timeout
        self.debounceTimeout = setTimeout(() => {
          self.currentPage = null;
          self.currentPage = 1;
          self.getTableData();
        }, 500);
      },
        
    },

    watch:{
      'recordsPerPage'(newVal, oldVal){
        var self = this
        if(oldVal && newVal && newVal != oldVal){
          this.loadFirstPage();
        }
      },
      "currentPage"(newVal, oldVal){
        if(oldVal && newVal && newVal != oldVal){
          this.currentPage = newVal;
          this.getTableData();
        }
      },
    },
}
</script>
