<style type="text/css">
  .dz-message {
    margin: 6.5rem 0 3rem !important;
    font-size: 21px;
    font-weight: 200;
  }
</style>
<template>
 
    <form ref="fileForm" class="dropzone needsclick" id="file-dropzone">
      <div class="dz-message needsclick">
          Drop file here or click to upload
        </div>
      <div class="fallback">
        <input name="file" type="file" />
      </div>
    </form>
    <div>
      <template v-if="isUploadError">
      	<small class="text-danger">{{ uploadErrorMsg }}</small>
      </template>
      
    </div>
</template>

<script>
import jQuery from 'jquery';
import "dropzone/dist/dropzone.css";
import Dropzone from "dropzone";


export default {
  name: "FileUpload",
  data() {
	return {
		isUploadError: false,
		uploadErrorMsg: ''
	}  
  },
  props:{
    data: {type: Object, default: {}},
    url: {type: String}
  },
  mounted() {
	  let self = this;
    const previewTemplate = `<div class="dz-preview dz-file-preview">
                  <div class="dz-details">
                    <div class="dz-thumbnail">
                      <img data-dz-thumbnail>
                      <span class="dz-nopreview">No preview</span>
                      <div class="dz-success-mark"></div>
                      <div class="dz-error-mark"></div>
                      <div class="dz-error-message"><span data-dz-errormessage></span></div>
                      <div class="progress">
                        <div class="progress-bar progress-bar-primary" role="progressbar" aria-valuemin="0" aria-valuemax="100" data-dz-uploadprogress></div>
                      </div>
                    </div>
                    <div class="dz-filename" data-dz-name></div>
                    <div class="dz-size" data-dz-size></div>
                  </div>
                  </div>`;

    const options = {
      url: this.url, 
      paramName: "file",
      maxFilesize: 5, // in MB
      addRemoveLinks: true,
      maxFiles: 1,
      init: function () {
    	  
        this.on("maxfilesexceeded", function (file) {
          this.removeAllFiles(); // Remove all files except the latest one
          this.addFile(file); // Add the latest file again
        });
        this.on("sending", function (file, xhr, formData) {
			jQuery.each( self.data, function ( index, value ) {
				console.log("sending call jquery ", index, value);
				formData.append(index, value);
			});
        });
      },
      headers: {
          'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
      }
    };

    this.dropzone = new Dropzone(this.$refs.fileForm, options);

    // Listen to events
    this.dropzone.on("success", this.handleSuccess);
    this.dropzone.on("error", this.handleError);
  },
  methods: {
    handleSuccess(file, response) {
      //console.log("File uploaded successfully", response.data);
    	this.isUploadError = false;
    	this.uploadErrorMsg = '';
      var self = this;
      self.data.file_name = response.data;
      self.$emit('update-filename', response.data);
    },
    handleError(file, error) {
    	this.isUploadError = true;
    	this.uploadErrorMsg = error.data.message;
      //console.log("Error uploading file", error);
    },
  },
  beforeDestroy() {
    if (this.dropzone) {
      this.dropzone.destroy();
    }
  },
};
</script>

