<style type="text/css">
    .table th{
        text-transform: none !important;
    }

</style>
<template>
    <div>
        <div class="card">
          <h5 class="card-header">My Reports > {{ folder_name }}</h5>
          <div class="table-responsive text-nowrap">
            <table class="table">
                <thead>
                    <tr>
                      <th><strong>Folder/Report Name</strong></th>
                      <th><strong>Created/Uploaded Date</strong></th>
                      <th><strong>Created/Uploaded By</strong></th>
                      <th><strong>Actions</strong></th>
                    </tr>
                  </thead>
                  <tbody class="table-border-bottom-0">
                    <tr v-for="report in reports">
                      <td> <span class="fw-medium">{{report.fr_name}}</span></td>
                      <td>{{$filters.dateFormat(report.created_at)}}</td>
                      <td>
                            <div class="d-flex justify-content-start align-items-center user-name">
                            	<template v-if="report.user_photo">
                            		<div class="avatar-wrapper"><div class="avatar me-3"><img :src="'images/avatars/'+report.user_photo" alt="Avatar" class="rounded-circle"></div></div>
                            	</template>
                              <div class="d-flex flex-column"><a href="javascript::void(0)" class="text-body text-truncate">
                                <span class="fw-medium">{{report.user_full_name}}</span></a></div>
                            </div>
                        </td>
                      <td>
                        <div class="d-flex align-items-center">
                        	<template v-if="report.type == 'parent_folder'">
                        		<a :href="'/my-reports'" class="text-body downlaod-record">[..]</a>
                        	</template>
                        	<template v-else-if="report.type == 'folder'">
                        		<a :href="'/my-reports/folders/'+report.id+'/reports'" class="text-body downlaod-record"><i class="ti ti-folder ti-sm mx-2"></i></a>
                        	</template>
                        	<template v-else>
	                        	<template v-if="report.full_path && report.full_path != '/'">
	                        		<a :href="'/uploads/reports'+report.full_path+'/'+report.file_name" class="text-body downlaod-record"><i class="ti ti-download ti-sm mx-2"></i></a>
	                        	</template>
	                        	<template v-else>
	                            	<a :href="'/uploads/reports/'+report.file_name" class="text-body downlaod-record"><i class="ti ti-download ti-sm mx-2"></i></a>
	                            </template>
                        	</template>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="reports.length==0">
                      <td colspan="4" class="text-center">No Record Found.</td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'reports',
    components:{
    },
    props: [],
    data(){
        return{
        	folder_id: this.$route.params.folder_id,
        	folder_name: '',
           	reports: []
        }   
    },

    created() {
        var self =this;
        self.getReports();
        self.getFolderDet();
    },
    setup() {
   
    },

    computed:{
        
    },

    methods:{
      getReports(){
        var self = this;
        self.$axios.get('/my-reports/folders/'+self.folder_id+'/reports')
          .then(response => {
            if (!response.data.error){                    
                self.reports = response.data.input_data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },
      getFolderDet() {
          var self = this;
          self.$axios.get('/report/folders/'+self.folder_id)
            .then(response => {
              if (!response.data.error){                    
                  self.folder_name = response.data.input_data.name;
              }
            })
            .catch(error => {
              console.error(error);
            });
      }
        
    },

    watch:{
        
    },
}
</script>