<style type="text/css">
    .required {
        color: #d16e6c;
        font-weight: bold;
        padding-left: 3px;
    }

</style>
<template>
    <div class="content-wrapper">
       <div class="container-xxl flex-grow-1 container-p-y">

          <div class="row justify-content-between mb-2">
              <div class="col-auto">
                <div class="d-flex align-items-center gap-2">
                  <h4 class="fw-bold mb-1"> Fleet Size Evolution</h4>
                </div>
              </div>
              <div class="col-auto gap-2">
                <div class="d-flex align-items-center gap-2">
                  <button type="button" @click="downloadChart" class="btn btn-outline-primary waves-effect">
                    <span class="ti-xs ti ti-download me-1"></span>Download Chart
                  </button>
                  <button type="button" v-if="!$store.state.AppConfig.fleetSizeCSVDownloading && !downloading_complete" @click="downloadCsv" class="btn btn-outline-primary waves-effect">
                    <span class="ti-xs ti ti-download me-1"></span>Download CSV
                  </button>

                  <button v-if="$store.state.AppConfig.fleetSizeCSVDownloading" class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border me-1" role="status" aria-hidden="true"></span>
                    Downloading...
                  </button>

                  <router-link v-if="downloading_complete" @click="resetDownloadStatus" class="btn btn-primary waves-effect" :to="'/files/'+csv_file_name" target="_blank"><span class="ti-xs ti ti-download me-1"></span>Download Ready</router-link>

                   <router-link  class="btn btn-outline-primary waves-effect" to="/download-history">Download History</router-link>
                </div>
              </div>
          </div>

          <div class="card mb-4">
            <h5 class="card-header border-bottom">Filters</h5>
            <VeeForm  ref="filterForm" as="div" class="needs-validation" name="filter" v-slot="{ errors }">
              <form class="card-body">
                <div class="row g-3">
                  <!-- <pre>{{filter}}</pre> -->
                  <div class="col-md-3" >
                    <label class="form-label" for="countries"><strong>Country</strong></label>
                    <select
                      id="countries"
                      name="countries"
                      class="select2 form-select form-control"
                      v-model="filter.country"
                    >
                      <option value="">All</option>
                      <option v-if="$store.state.AppConfig.countries.length" v-for="country in $store.state.AppConfig.countries" :value="country.Country">{{country.Country}}</option>
                    </select>
                  </div>

                  <div class="col-md-3" >
                    <label class="form-label" for="divisions"><strong>Division</strong></label>
                    <select
                      id="divisions"
                      name="divisions"
                      class="select2 form-select form-control"
                      v-model="filter.division"
                    >
                      <option value="">All</option>
                      <option v-if="divisions.length" v-for="division in divisions" :value="division.Division">{{division.Division}}</option>
                    </select>
                  </div>

                  <div class="col-md-3" >
                    <label class="form-label" for="makes"><strong>Make</strong></label>
                    <select
                      id="makes"
                      name="makes"
                      class="select2 form-select form-control"
                      v-model="filter.make"
                    >
                      <option value="">All</option>
                      <option v-if="makes.length" v-for="make in makes" :value="make.Make">{{make.Make}}</option>
                    </select>
                  </div>

                  <div class="col-md-3" >
                    <label class="form-label" for="suppliers"><strong>Suppliers</strong></label>
                    <select
                      id="suppliers"
                      name="suppliers"
                      class="select2 form-select form-control"
                      v-model="filter.supplier"
                    >
                      <option value="">All</option>
                      <option v-if="$store.state.AppConfig.suppliers.length" v-for="supplier in $store.state.AppConfig.suppliers" :value="supplier.SupplierCode">{{supplier.SupplierName}}</option>
                    </select>
                  </div>

                  <div class="col-md-3">
                    <div class="form-password-toggle">
                      <label class="form-label" for="multicol-confirm-password"><strong>Start Date <span class="required">*</span></strong></label>
                      <!-- <div class="input-group input-group-merge">
                        <input
                          type="date"
                          id="multicol-confirm-password"
                          class="form-control"
                          v-model="filter.start_date"
                        />
                      </div> -->
                      <Datepicker 
                          locale="en" 
                          v-model="filter.start_date" 
                          autoApply
                          :enableTimePicker="false"
                          :monthChangeOnScroll="false"
                          format="dd-MM-yyyy"
                          >
                        </Datepicker>

                        <Field id="config-date" type="hidden" class="form-control" 
                          rules="required"  name="start_date" 
                          v-model="filter.start_date" 
                          label="Start Date">
                        </Field>
                        <small class="text-danger">{{ errors.start_date }}</small>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-password-toggle">
                      <label class="form-label" for="multicol-confirm-password"><strong>End Date<span class="required">*</span></strong></label>
                        <Datepicker 
                          locale="en" 
                          v-model="filter.end_date" 
                          autoApply
                          :enableTimePicker="false"
                          :monthChangeOnScroll="false"
                          format="dd-MM-yyyy"
                          >
                        </Datepicker>

                        <Field id="config-date" type="hidden" class="form-control" 
                          rules="required"  name="end_date" 
                          v-model="filter.end_date" 
                          label="End Date">
                        </Field>
                        <small class="text-danger">{{ errors.end_date }}</small>
                    </div>
                  </div>
                  <div class="col-md-3" >
                    <label class="form-label" for="model"><strong>Model</strong></label>
                    <select
                      id="vehModel"
                      name="vehModel"
                      class="select2 form-select form-control"
                      v-model="filter.vehModel"
                    >
                      <option value="">All</option>
                      <option v-if="vehModels.length" v-for="vehModel in vehModels" :value="vehModel.Model">{{vehModel.Model}}</option>
                    </select>
                  </div>
                </div>
                <div class="pt-4">
                  <button type="button" @click="submit" class="btn btn-primary me-sm-3 me-1">Apply</button>
                  <router-link  class="btn btn-outline-primary waves-effect" to="/dashboard">Cancel</router-link>
                </div>
              </form>
            </VeeForm>
          </div>

          <div class="card mb-4">
              <h5 class="card-header">Graphical View</h5>
              <div class="card-body">
                  <div class="row g-3">
                      <!-- <div class="col-md-3">
                          <label class="form-label" for="multicol-confirm-password">Chart By</label>
                          <select id="client" class="select2 form-select" data-allow-clear="true">
                              <option value="">Select Chart By</option>
                              <option value="AL" >Country</option>
                              <option value="AK">Supplier</option>
                          </select>
                      </div> -->
                      <!-- <div class="col-md-12" v-if="chartData.length==0">
                          <span>No Data Found</span>
                      </div> -->
                      <div class="col-md-12" id="fleetSizeChart">
                          <!-- <canvas ref="polarChart" class="chartjs" data-height="337"></canvas> -->
                          <canvas ref="barChart" class="chartjs" data-height="400" height="500"></canvas>
                      </div>
                  </div>
                  <spinner :active="loading" :is-full-page="true"></spinner> 
                  <spinner :active="downloading" :is-full-page="true"></spinner> 
              </div>
          </div>


        </div>
    </div>
</template>

<script>
import {Form, Field ,useFieldError,useFormErrors,useValidateForm,useField,useForm} from 'vee-validate';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Pagination from '../../components/Pagination';
import { jsPDF } from "jspdf";
export default {
    name: 'fleet-size',
    components:{
      'VeeForm': Form,
      Datepicker,
      Field,
      Pagination,
    },
    props: [],
    data(){
        return{
           myChart: "",
           divisions: [],
           makes: [],
           vehModels: [],
           filter: {
              "country":"",
              "supplier":"",
              "division":"",
              "make":"",
              "vehModel":"",
              "start_date":"",
              "end_date":"",
           },
           chartData:[],
           barChartVar: null,
           cyanColor: '#00bcd4',
          yAxisMin: 0,
          yAxisMax: 400,
          loading: false,
          downloading_complete: false,
          csv_file_name: "",
          currentPage:1,
          recordsPerPage:10,
          tableData: [],
          totalData: 0,
        }   
    },
    created() {
      var self = this;
      self.getDivisions();
      self.getMakes();
      self.getModels();

      const currentDate = new Date();
      const startDate = new Date(currentDate);
      startDate.setMonth(currentDate.getMonth() - 12);
      self.filter.start_date = startDate;
      self.filter.end_date = currentDate;

      if(self.filter.start_date && self.filter.end_date){
        self.getGraphData();
        self.getTableData();
      }
    },
    mounted() {
       
    },
    setup() {
    },
    computed:{
        
    },
    methods:{
      getDivisions(){
        var self = this;
        self.$axios.get('/divisions')
          .then(response => {
            if (!response.data.error){                    
                self.divisions = response.data.input_data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },
      getMakes(){
        var self = this;
        self.$axios.get('/makes')
          .then(response => {
            if (!response.data.error){                    
                self.makes = response.data.input_data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },
      getModels(){
          var self = this;
          self.$axios.get('/models')
            .then(response => {
              if (!response.data.error){                    
                  self.vehModels = response.data.input_data;
                  console.log('self.vehModels', self.vehModels);
              }
            })
            .catch(error => {
              console.error(error);
            });
        },
      async submit(){
        var self = this;
        const form = this.$refs.filterForm;
        const isValid = await form.validate();

        if (isValid.valid) {
          self.getGraphData();
          self.getTableData();
        }
      },
      getGraphData(){
          var self = this;
          self.loading = true;
      	self.yAxisMin = 0;
    	self.yAxisMax = 400;
          self.$axios.post('/fleet-size-graph', self.filter)
          .then(response => {
            if (!response.data.error){ 
                self.chartData = response.data.input_data;
                self.loading = false;

                const dataValues = this.chartData.map(item => item.data);
                /*self.yAxisMin = Math.min(...dataValues) - ((2 / 100) * Math.min(...dataValues));
                self.yAxisMax = Math.max(...dataValues) + ((2 / 100) * Math.max(...dataValues));*/
                let yAxisMin = Math.min(...dataValues) - ((2 / 100) * Math.min(...dataValues));
                let yAxisMax = Math.max(...dataValues) + ((2 / 100) * Math.max(...dataValues));
                if (yAxisMin >=400) {
                	self.yAxisMin = yAxisMin;
                	self.yAxisMax = yAxisMax;
                }

                self.updateChart();
            }
          })
          .catch(error => {
            console.error(error);
          });
      },
      updateChart() {
          const canvasElement = this.$refs.barChart;
          if (canvasElement && canvasElement.getContext) {

            const context = canvasElement.getContext('2d');
            context.clearRect(0, 0, canvasElement.width, canvasElement.height);

            const existingChart = Chart.getChart(canvasElement);
            if (existingChart) {
                existingChart.destroy();
            }

            this.barChartVar = new Chart(context, {
              type: 'line',
              data: {
                labels: this.chartData.map(item => item.label),
                datasets: [
                  {
                    data: this.chartData.map(item => item.data),
                    backgroundColor: this.cyanColor,
                    borderColor: '#2B9AFF',
                    tension: 0.5,
                    pointStyle: 'circle',
                    fill: false,
                    pointRadius: 5,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 5,
                    pointBorderColor: 'transparent',
                    pointHoverBorderColor: "#4F5D70",
                    pointHoverBackgroundColor: "#2B9AFF"
                  }
                ]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                  duration: 500
                },
                plugins: {
                  tooltip: {
                    rtl: false,
                    backgroundColor: "#2f3349",
                    titleColor: "#cfd3ec",
                    bodyColor: "#a5a3ae",
                    borderWidth: 1,
                    borderColor: "#dbdade"
                  },
                  legend: {
                    display: false
                  }
                },
                scales: {
                  x: {
                    grid: {
                      color: "#dbdade",
                      drawBorder: false,
                      borderColor: "#dbdade",
                    },
                    ticks: {
                      color: "#a5a3ae",
                    }
                  },
                  y: {
                    min: this.yAxisMin,
                    max: this.yAxisMax,
                    grid: {
                      color:"#dbdade",
                      drawBorder: false,
                      borderColor: "#dbdade",
                    },
                    ticks: {
                      stepSize: (this.yAxisMax - this.yAxisMin) / 5,
                      color: "#a5a3ae",
                      callback: function (value, index, values) {
                        return Math.round(value/ 10) * 10;
                      },
                    },
                    title: {
                        display: true,
                        text: 'Vehicles on Fleet(last day of the month)',
                    },
                  }
                }
              }
            });
          }
      },

      downloadCsv() {

        var self = this;
        //self.downloading = true;
        self.$store.commit('SET_FLEETSIZE_CSV_DOWNLOADING', true);
        const queryParams = Object.keys(self.filter)
              .map(key => {
                  if (key === 'start_date' || key === 'end_date') {
                      return encodeURIComponent(key) + '=' + encodeURIComponent(new Date(self.filter[key]).toISOString());
                  } else {
                      return encodeURIComponent(key) + '=' + encodeURIComponent(self.filter[key]);
                  }
              })
              .join('&');

        const apiUrl = '/download-csv/fleet-size?' + queryParams;

        self.$axios.get(apiUrl)
        .then(response => {
           /* const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'fleet-size.csv');
            document.body.appendChild(link);
            link.click();*/

            self.downloading_complete = true;
            self.csv_file_name = response.data.input_data.csv_file_name;

            self.$store.commit('SET_FLEETSIZE_CSV_DOWNLOADING', false);
            //self.downloading = false;
        })
        .catch(error => {
            console.error('Error downloading CSV', error);
        });
      },

      resetDownloadStatus() {
          this.downloading_complete = false;
      },
      downloadChart() {
    	// get size of fleetSizeChart page
    	let fleetSizeChartHeight = jQuery('#fleetSizeChart').innerHeight();
    	let fleetSizeChartWidth = jQuery('#fleetSizeChart').innerWidth();
    	// create a new canvas object that we will populate with all other canvas objects
    	let pdfCanvas = $('<canvas />').attr({
    		id: "canvaspdf",
    		width: fleetSizeChartWidth,
    		height: fleetSizeChartHeight
    	});
    	// keep track canvas position
    	let pdfctx = jQuery(pdfCanvas)[0].getContext('2d');
    	let pdfctxX = 0;
    	let pdfctxY = 0;
    	let buffer = 100;
    	// for each chart.js chart
    	jQuery("canvas").each(function(index) {
    		// get the chart height/width
    		let canvasHeight = jQuery(this).innerHeight();
    		let canvasWidth = jQuery(this).innerWidth();
    		// draw the chart into the new canvas
    		pdfctx.drawImage(jQuery(this)[0], pdfctxX, pdfctxY, canvasWidth, canvasHeight);
    		pdfctxX += canvasWidth + buffer;
    		// our report page is in a grid pattern so replicate that in the new canvas
    		if (index % 2 === 1) {
    			pdfctxX = 0;
    			pdfctxY += canvasHeight + buffer;
    		}
    	});
    	const today = new Date();
    	const yyyy = today.getFullYear();
    	let mm = today.getMonth() + 1; // Months start at 0!
    	let dd = today.getDate();
    	let h = today.getHours();
    	let i = today.getMinutes();
    	let s = today.getSeconds();
    	if (dd < 10) dd = '0' + dd;
    	if (mm < 10) mm = '0' + mm;
    	if (h < 10) h = '0'+h;
    	if (i < 10) i = '0'+i;
    	if (s < 10) s = '0'+s;
    	// create new pdf and add our new canvas as an image
    	let pdf = new jsPDF('l', 'pt', [fleetSizeChartWidth, fleetSizeChartHeight]);
    	pdf.text('Downloaded Date and Time: '+dd+'-'+mm+'-'+yyyy+' '+h+':'+i+':'+s, 10, 25);
    	pdf.text('Fleet Size Evolution', 555, 25);
    	pdf.addImage(jQuery(pdfCanvas)[0], 'PNG', 10, 45);
    	// download the pdf
    	pdf.save('fleetsize.pdf');
      },

      getTableData(){
          var self = this;
          self.loading = true;

          self.filter.per_page = self.recordsPerPage;
          self.$axios.post('/fleet-size-data/'+ self.currentPage, self.filter)
          .then(response => {
            if (!response.data.error){ 
                self.tableData = response.data.input_data.data;
                self.totalData = response.data.input_data.total;
                self.loading = false;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      loadFirstPage(){
        var self = this;
        clearTimeout(self.debounceTimeout); // Clear the previous timeout
        self.debounceTimeout = setTimeout(() => {
          self.currentPage = null;
          self.currentPage = 1;
          self.getTableData();
        }, 500);
      },
    },

    watch:{
      'recordsPerPage'(newVal, oldVal){
        var self = this
        if(oldVal && newVal && newVal != oldVal){
          this.loadFirstPage();
        }
      },
      "currentPage"(newVal, oldVal){
        if(oldVal && newVal && newVal != oldVal){
          this.currentPage = newVal;
          this.getTableData();
        }
      },
    },
}
</script>
