<style type="text/css">
    

</style>
<template>
    <div>
        <div class="card">
            <div class="card-datatable table-responsive">
                <div class="row mx-1 mt-4"><div class="col-sm-12 col-md-3"> <h5 class="card-title mb-3">Notes</h5></div>
                    <div class="col-sm-12 col-md-9">
                        <div class="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                            <!-- <div class="me-3"><div id="DataTables_Table_0_filter" class="dataTables_filter"><label><input type="search" class="form-control" placeholder="Search.." aria-controls="DataTables_Table_0"></label></div>
                            </div> -->
                            <div class="dt-buttons btn-group flex-wrap">
                                <button class="btn add-new btn-primary mb-3 mb-md-0" tabindex="0" aria-controls="DataTables_Table_0" type="button" @click="addNewNotes"><span>Add Notes</span></button> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive text-nowrap">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Client</th>
                        <th>Notes</th>
                        <th>Last Modified By</th>
                        <th>Last Modified At</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody class="table-border-bottom-0">
                      <tr v-if="notes.length>0" v-for="note_det in notes">
                      	<td>{{note_det.CompanyName}}</td>
                        <td>{{note_det.notes}}</td>
                        <td>
                            <div class="d-flex justify-content-start align-items-center user-name">
                            	<template v-if="note_det.modified_by_photo">
                            		<div class="avatar-wrapper"><div class="avatar me-3"><img :src="'/images/avatars/'+note_det.modified_by_photo" alt="Avatar" class="rounded-circle"></div></div>
                            	</template>
                              <div class="d-flex flex-column"><a href="javascript::void(0)" class="text-body text-truncate">
                                <span class="fw-medium">{{note_det.modified_by_full_name}}</span></a></div>
                            </div>
                        </td>
                        <td>
                        	{{$filters.dateFormat(note_det.updated_at)}}
                        </td>
                        <td>
                          <div class="d-flex align-items-center">
                                <a href="javascript:void(0);"  @click="editNote(note_det.id)" class="text-body"><i class="ti ti-edit ti-sm me-2"></i></a>
                                <a href="javascript:void(0);" @click="openConfirm(note_det.id)" class="text-body delete-record"><i class="ti ti-trash ti-sm mx-2"></i></a>
                            </div>
                        </td>
                      </tr>
	                    <tr v-else>
	                        <td colspan="5" class="text-center">No record found.</td>
	                    </tr>
                    </tbody>
                  </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Swal from 'sweetalert2'

export default {
    name: 'notes',
    components:{
    },
    props: [],
    data(){
        return{
        	notes: [],
        }   
    },

    created() {
        var self= this;
        self.getNotes();
    },
    setup() {
   
    },

    computed:{
        
    },

    methods:{
    	addNewNotes(){
            this.$router.push('details/create');
        },

        getNotes(){
            var self = this;
            self.$axios.get('/notes')
              .then(response => {
                if (!response.data.error){                    
                    self.notes = response.data.input_data.data;
                }
              })
              .catch(error => {
                console.error(error);
              });
        },

        async openConfirm(id){
            var self = this;
            Swal.fire({
                    title: "Confirm",
                    text: "Are you sure you want to delete this note?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    customClass: {
                        confirmButton: 'btn btn-danger',
                        cancelButton: 'btn btn-outline-secondary ms-1',
                    },
                    buttonsStyling: false
                }).then(function (result) {
                    if(result.value){
                        self.deleteNote(id);
                    }
                })
        }, 

        deleteNote(id) {
            const self = this;
            self.$axios.delete('notes/' + id).then(function (response) {
                if (response.data.error == false) {
                	self.getNotes();
                    toastr['success'](response.data.input_data.message, 'Success', {
                        closeButton: true,
                        tapToDismiss: false,
                        timeOut: 5000,
                        rtl: false,
                    });
                }
            })
        },

        editNote(id){
            this.$router.push('details/'+id);
        },
        
    },

    watch:{
        
    },
}
</script>
