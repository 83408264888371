<style type="text/css">
    

</style>
<template>
    <div v-if="$store.state.AppConfig.loggedin_user.role_name =='superadmin'">
        <div class="card">
            <div class="card-datatable table-responsive">
                <div class="row mx-1 mt-4"><div class="col-sm-12 col-md-3"> <h5 class="card-title mb-3">Users</h5></div>
                    <div class="col-sm-12 col-md-9">
                        <div class="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                            <!-- <div class="me-3"><div id="DataTables_Table_0_filter" class="dataTables_filter"><label><input type="search" class="form-control" placeholder="Search.." aria-controls="DataTables_Table_0"></label></div>
                            </div> -->
                            <div class="dt-buttons btn-group flex-wrap">
                                <button class="btn add-new btn-primary mb-3 mb-md-0" tabindex="0" aria-controls="DataTables_Table_0" type="button" @click="addNewUser"><span>Add User</span></button> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive text-nowrap">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody class="table-border-bottom-0">
                      <tr v-for="user in users">
                        <td>
                            <div class="d-flex justify-content-start align-items-center user-name">
                              <div class="avatar-wrapper"><div class="avatar me-3"><img :src="'/images/avatars/'+user.logo" alt="Avatar" class="rounded-circle"></div></div> 
                              <div class="d-flex flex-column">
                                <router-link   class="text-body text-truncate" :to="'/users/'+user.id+'/details'"><span class="fw-medium">{{user.first_name}} {{user.last_name}}</span></router-link>
                                <!-- <a href="app-user-view-account.html" class="text-body text-truncate">
                                <span class="fw-medium">{{user.first_name}} {{user.last_name}}</span></a> -->
                            </div>
                            </div>
                        </td>
                        <td>{{user.email}}</td>
                        <td>
                            <span class="text-truncate d-flex align-items-center">
                                <span class="badge badge-center rounded-pill bg-label-warning w-px-30 h-px-30 me-2"><i class="ti ti-user ti-sm"></i></span>{{user.role_caption}}</span>
                        </td>
                        <td>
                            <span class="badge bg-label-primary me-1" v-if="user.is_active==0">Active</span>
                            <span class="badge bg-label-danger me-1" v-else>Inactive</span>
                        </td>
                        <td>
                          <div class="d-flex align-items-center">
                                <a href="javascript:void(0);"  @click="editUser(user.id)" class="text-body"><i class="ti ti-edit ti-sm me-2"></i></a>
                                <a href="javascript:void(0);" @click="openConfirm(user.id)" class="text-body delete-record"><i class="ti ti-trash ti-sm mx-2"></i></a>
                            </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
      Access denied.
    </div>
</template>

<script>

import Swal from 'sweetalert2'

export default {
    name: 'users',
    components:{
    },
    props: [],
    data(){
        return{
           users: [],
        }   
    },

    created() {
        var self= this;
        self.getUsers();
    },
    setup() {
   
    },

    computed:{
        
    },

    methods:{
        addNewUser(){
            this.$router.push('create/details');
        },

        getUsers(){
            var self = this;
            self.$axios.get('/users')
              .then(response => {
                if (!response.data.error){                    
                    self.users = response.data.input_data;
                }
              })
              .catch(error => {
                console.error(error);
              });
        },

        async openConfirm(id){
            var self = this;
            Swal.fire({
                    title: "Confirm",
                    text: "Are you sure you want to delete this user?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    customClass: {
                        confirmButton: 'btn btn-danger',
                        cancelButton: 'btn btn-outline-secondary ms-1',
                    },
                    buttonsStyling: false
                }).then(function (result) {
                    if(result.value){
                        self.deleteUser(id);
                    }
                })
        }, 

        deleteUser(id) {
            const self = this;
            self.$axios.delete('users/' + id).then(function (response) {
                if (response.data.error == false) {
                     for (var i = 0; i <  self.users.length; i++) {
                        if (id ==  self.users[i].id) {
                            self.users.splice(i, 1);

                            toastr['success'](response.data.input_data.message, 'Success', {
                                closeButton: true,
                                tapToDismiss: false,
                                timeOut: 5000,
                                rtl: false,
                            });

                            break;
                        }
                    }
                }
            })
        },

        editUser(id){
            this.$router.push(id+'/details');
        },
        
    },

    watch:{
        
    },
}
</script>
