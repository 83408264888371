<template>
    <div>
        <div class="card">
            <div class="card-datatable table-responsive">
                <div class="row mx-1 mt-4"><div class="col-sm-12 col-md-3"> <h5 class="card-title mb-3">My Announcements</h5></div>
                    <div class="col-sm-12 col-md-9">
                    </div>
                </div>
                <div class="table-responsive text-nowrap">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody class="table-border-bottom-0">
                      <tr v-if="announcements.length>0" v-for="announcement in announcements">
                        <td>{{announcement.announcements}}</td>
                      </tr>
	                    <tr v-else>
	                        <td colspan="5" class="text-center">No record found.</td>
	                    </tr>
                    </tbody>
                  </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Swal from 'sweetalert2'

export default {
    name: 'announcements',
    components:{
    },
    props: [],
    data(){
        return{
        	announcements: [],
        }   
    },

    created() {
        var self= this;
        self.getAnnouncements();
    },
    setup() {
   
    },

    computed:{
        
    },

    methods:{
        addNewAnnouncements(){
            this.$router.push('details/create');
        },

        getAnnouncements(){
            var self = this;
            self.$axios.get('/my-announcements')
              .then(response => {
                if (!response.data.error){                    
                    self.announcements = response.data.input_data;
                }
              })
              .catch(error => {
                console.error(error);
              });
        },

        
    },

    watch:{
        
    },
}
</script>
