<style type="text/css">
    .table th{
        text-transform: none !important;
    }

</style>
<template>
    <div v-if="$store.state.AppConfig.loggedin_user.role_name =='superadmin'">
        <div class="card">
          <h5 class="card-header">Report History</h5>
          <div class="table-responsive text-nowrap">
            <table class="table">
                <thead>
                    <tr>
                      <th><strong>Client</strong></th>
                      <th><strong>Report Name</strong></th>
                      <th><strong>Uploaded Date</strong></th>
                      <th><strong>Uploaded By</strong></th>
                      <th><strong>Actions</strong></th>
                    </tr>
                  </thead>
                  <tbody class="table-border-bottom-0">
                    <tr v-for="report in reports">
                      <td v-if="$store.state.AppConfig.loggedin_user.role_name == 'superadmin'">
                        <span v-if="$store.state.AppConfig.customers.length" v-for="customer in $store.state.AppConfig.customers">
                            <span v-if="report.client_id == customer.CustomerID">{{customer.CompanyName}}</span>
                        </span>
                      </td>
                      <td> <span class="fw-medium">{{report.report_name}}</span></td>
                      <td>{{$filters.dateFormat(report.created_at)}}</td>
                      <td>
                            <div class="d-flex justify-content-start align-items-center user-name">
                              <div class="avatar-wrapper"><div class="avatar me-3"><img :src="'/images/avatars/'+report.uploaded_by_photo" alt="Avatar" class="rounded-circle"></div></div> 
                              <div class="d-flex flex-column"><a href="javascript::void(0)" class="text-body text-truncate">
                                <span class="fw-medium">{{report.uploaded_by_full_name}}</span></a></div>
                            </div>
                        </td>
                      <td>
                        <div class="d-flex align-items-center">
                        	<template v-if="report.full_path && report.full_path != '/'">
                        		<a :href="'/uploads/reports'+report.full_path+'/'+report.file_name" class="text-body downlaod-record" download><i class="ti ti-download ti-sm mx-2"></i></a>
                        	</template>
                        	<template v-else>
                        		<a :href="'/uploads/reports/'+report.file_name" class="text-body downlaod-record" download><i class="ti ti-download ti-sm mx-2"></i></a>
                        	</template>
                            <a href="javascript:void(0);" @click="openConfirm(report.id)" class="text-body delete-record"><i class="ti ti-trash ti-sm mx-2"></i></a>
                        </div>
                      </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
    </div>
    <div v-else>
      Access denied.
    </div>
</template>

<script>
import Swal from 'sweetalert2'

export default {
    name: 'report-history',
    components:{
    },
    props: [],
    data(){
        return{
           reports: []
        }   
    },

    created() {
        var self =this;
        self.getReports();
    },
    setup() {
   
    },

    computed:{
        
    },

    methods:{
      getReports(){
        var self = this;
        self.$axios.get('/reports')
          .then(response => {
            if (!response.data.error){                    
                self.reports = response.data.input_data;
            }
          })
          .catch(error => {
            console.error(error);
          });
      },

      async openConfirm(id){
        var self = this;
        Swal.fire({
                title: "Confirm",
                text: "Are you sure you want to delete this report?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                customClass: {
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-outline-secondary ms-1',
                },
                buttonsStyling: false
            }).then(function (result) {
                if(result.value){
                    self.deleteReport(id);
                }
            })
      }, 

      deleteReport(id) {
          const self = this;
          self.$axios.delete('reports/' + id).then(function (response) {
              if (response.data.error == false) {
                   for (var i = 0; i <  self.reports.length; i++) {
                      if (id ==  self.reports[i].id) {
                          self.reports.splice(i, 1);

                          toastr['success'](response.data.input_data.message, 'Success', {
                              closeButton: true,
                              tapToDismiss: false,
                              timeOut: 5000,
                              rtl: false,
                          });

                          break;
                      }
                  }
              }
          })
      },
        
    },

    watch:{
        
    },
}
</script>
