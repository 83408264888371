import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/Login.vue';
import LoginOtp from '../views/Login_otp.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';

import PageNotFound from '../components/PageNotFound.vue';
import Dashboard from '../views/Dashboard.vue';

import ReportUpload from '../views/reports/Upload.vue';
import ReportHistory from '../views/reports/History.vue';
import ReportFolder from '../views/reports/Folder.vue';
import MyReports from '../views/my_reports/List.vue';
import MyReportsFolderReport from '../views/my_reports/FolderReport.vue';

import PolicyUpload from '../views/policies/Upload.vue';
import PolicyHistory from '../views/policies/History.vue';
import PolicyFolder from '../views/policies/Folder.vue';
import Policies from '../views/Policies.vue';

import ReportDetail from '../views/ReportDetail.vue';
import DownloadHistory from '../views/download_history/List.vue';
import User from '../views/users/List.vue';
import UserDetail from '../views/users/Detail.vue';
import Profile from '../views/Profile.vue';
import tblVehCostAllocation from '../views/report_details/tblVehCostAllocation.vue';
import AnnouncementsList from '../views/announcements/List.vue';
import AnnouncementsDetails from '../views/announcements/Detail.vue';
import NotesList from '../views/notes/List.vue';
import NotesDetails from '../views/notes/Detail.vue';
import CaveatsList from '../views/caveats/List.vue';
import CaveatsDetails from '../views/caveats/Detail.vue';
import MyAnnouncementsList from '../views/my-announcements/List.vue';



const routes = [
    {
        // =============================================================================
        // FULL PAGE LAYOUTS
        // =============================================================================
        path: '/',
        component: () => import('../layouts/full-page/FullPage.vue'),
        children: [
            // =============================================================================
            // PAGES
            // =============================================================================
            {
                path: 'login',
                name: 'login',
                meta:{
                    title:  getPageTitle('Login'),
                },
                component:  Login
            },
            {
                path: '/login/otp',
                name: 'page-login-otp',
                meta: {
                    title: 'Login OTP',
                    requiresAuth: true,
                },
                component: LoginOtp
            },
            {
                path: 'forgot-password',
                name: 'forgot-password',
                meta:{
                    title:  getPageTitle('Forgot Password'),
                    requiresAuth: true,
                },
                component:  ForgotPassword
            },
             {
                path: 'reset-password',
                name: 'reset-password',
                meta:{
                    title:  getPageTitle('Reset Password'),
                    requiresAuth: true,
                },
                component:  ResetPassword
            }
        ]
    },
    {
        path: '/',
        component: () => import('../layouts/main/Main.vue'),
        children: [
        	{
            	path: 'dashboard',
                name: 'dashboard',
                meta:{
                    title: getPageTitle('Dashboard'),
                    requiresAuth: true,
                },
            	component:Dashboard
        	},
            {
                path: 'users',
                name: 'users',
                meta : {
                    title: getPageTitle('Users'),
                    requiresAuth: true, 
                    parent: true 
                },
                component: () => import('../views/users/App.vue'),
                children: [
                {
                    path: 'list',
                    name: 'users',
                    title: getPageTitle('Users'),
                    component: User,
                },
                {
                    path: ':id/details',
                    name: 'user-details',
                    title: getPageTitle('User Details'),
                    component: UserDetail,
                }
                ]
            },
            {
                path: 'profile',
                name: 'profile',
                meta:{
                    title: getPageTitle('Profile'),
                    requiresAuth: true,
                },
                component:Profile
            },
            /*{
                path: 'reports',
                name: 'reports',
                meta:{
                    title: getPageTitle('Reports'),
                    requiresAuth: true,
                },
                component:ReportUpload
            },*/
            {
                path: 'reports',
                name: 'reports',
                meta : {
                    title: getPageTitle('Reports'),
                    requiresAuth: true, 
                    parent: true 
                },
                component: () => import('../views/reports/App.vue'),
                children: [
                    {
                        path: 'upload',
                        name: 'report-upload',
                        title: getPageTitle('Upload Report'),
                        component: ReportUpload,
                    },
                    {
                        path: 'history',
                        name: 'report-history',
                        title: getPageTitle('Report History'),
                        component: ReportHistory,
                    },
                    {
                        path: 'folder',
                        name: 'report-folder',
                        title: getPageTitle('Report Folder'),
                        component: ReportFolder,
                    }
                ]
            },
            {
                path: 'my-reports',
                name: 'my-reports',
                meta:{
                    title: getPageTitle('My Reports'),
                    requiresAuth: true,
                },
                component:MyReports
            },
            {
                path: '/my-reports/folders/:folder_id/reports',
                name: 'my-reports-foler-report',
                meta:{
                    title: getPageTitle('My Reports'),
                    requiresAuth: true,
                },
                component:MyReportsFolderReport
            },
            {
                path: 'report/:type',
                name: 'report',
                meta:{
                    title: getPageTitle('Reports'),
                    requiresAuth: true,
                },
                component:ReportDetail
            },
            {
                path: 'download-history',
                name: 'download-history',
                meta:{
                    title: getPageTitle('Download History'),
                    requiresAuth: true,
                },
                component:DownloadHistory
            },
            {
                path: 'tblVehCostAllocation/:invoice_number',
                name: 'tblVehCostAllocation',
                meta:{
                    title: getPageTitle('tblVehCostAllocation'),
                    requiresAuth: true,
                },
                component:tblVehCostAllocation
            },
            {
                path: 'policies',
                name: 'policies',
                meta : {
                    title: getPageTitle('Policies'),
                    requiresAuth: true, 
                    parent: true 
                },
                component: () => import('../views/policies/App.vue'),
                children: [
                    {
                        path: 'upload',
                        name: 'policy-upload',
                        title: getPageTitle('Upload Policy'),
                        component: PolicyUpload,
                    },
                    {
                        path: 'history',
                        name: 'policy-history',
                        title: getPageTitle('Policy History'),
                        component: PolicyHistory,
                    },
                    {
                        path: 'folder',
                        name: 'policy-folder',
                        title: getPageTitle('Policy Folder'),
                        component: PolicyFolder,
                    }
                ]
            },
            {
                path: 'policies/list',
                name: 'policy',
                meta:{
                    title: getPageTitle('Policies'),
                    requiresAuth: true,
                },
                component:Policies
            },
            {
                path: 'announcements',
                name: 'announcements',
                meta : {
                    title: getPageTitle('Announcements'),
                    requiresAuth: true, 
                    parent: true 
                },
                component: () => import('../views/announcements/App.vue'),
                children: [
                    {
                        path: 'list',
                        name: 'announcements-list',
                        title: getPageTitle('Announcements List'),
                        component: AnnouncementsList,
                    },
                    {
                        path: 'details/:id',
                        name: 'announcements-details',
                        title: getPageTitle('Announcements Details'),
                        component: AnnouncementsDetails,
                    }
                ]
            },
            {
                path: 'notes',
                name: 'notes',
                meta : {
                    title: getPageTitle('Notes'),
                    requiresAuth: true, 
                    parent: true 
                },
                component: () => import('../views/notes/App.vue'),
                children: [
                    {
                        path: 'list',
                        name: 'notes-list',
                        title: getPageTitle('Notes List'),
                        component: NotesList,
                    },
                    {
                        path: 'details/:id',
                        name: 'notes-details',
                        title: getPageTitle('Notes Details'),
                        component: NotesDetails,
                    }
                ]
            },
            {
                path: 'caveats',
                name: 'caveats',
                meta : {
                    title: getPageTitle('Caveats'),
                    requiresAuth: true, 
                    parent: true 
                },
                component: () => import('../views/caveats/App.vue'),
                children: [
                    {
                        path: 'list',
                        name: 'caveats-list',
                        title: getPageTitle('Caveats List'),
                        component: CaveatsList,
                    },
                    {
                        path: 'details/:id',
                        name: 'caveats-details',
                        title: getPageTitle('Caveats Details'),
                        component: CaveatsDetails,
                    }
                ]
            },
            {
                path: 'my-announcements',
                name: 'my-announcements',
                meta:{
                    title: getPageTitle('My Announcements'),
                    requiresAuth: true,
                },
                component:MyAnnouncementsList
            },
            /*{
                path: 'policies',
                name: 'policies',
                meta:{
                    title: getPageTitle('Policies'),
                    requiresAuth: true,
                },
                component:Policies
            }*/
        ]
    },
    {   
        path: "/:pathMatch(.*)*", 
        component: PageNotFound 
    }/*,
    {
        path: '/',
        redirect: {name:DEFAULT_REDIRECT}
    },
    {
        path: '/:customer_code/dashboard',
        redirect: {name:DEFAULT_REDIRECT}
    }*/
];

const router = createRouter({
    history: createWebHistory(),
    routes
});
function getPageTitle(lable){
    return lable +' - Fleetworx';
}
router.afterEach((to, from) => {
    document.title = to.meta.title  || 'Fleetworx';
});
export default router
